.hero {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #0F0028; // Change this to your desired background color
  padding: 0 2rem;
  padding-bottom: 40px;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1900px;
}

.blueStar {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.greenStar {
  margin-bottom: 10px;
}

.heroText {
  font-family: 'Ranade', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 130%;
  letter-spacing: -0.04em;
  color: #ffffff;
  margin-bottom: 24px;
}

.heroSubText {
  font-family: 'Ranade', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  /* or 29px */

  letter-spacing: -0.02em;
  margin-bottom: 40px;

  /* Shades/Dark-15% */

  color: #DBD9DF;
}

.heroGraphicDiv {
  width: 600px;
}

@media (max-width: 991px) {
  .hero {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .heroText {
    margin-bottom: 0;
  }


  .heroGraphicDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    align-self: center;
  }

  .heroSubText {
    font-size: 16px;
  }

  .blueStar {
    width: 30px;
  }

  .greenStar {
    width: 30px;
  }

  .heroText {
    font-size: 30px;
  }

  .heroDiv {
    flex-direction: column !important;
  }

  .heroSubText {
    font-size: 16px;
  }
}
