.contactlessSection {
  padding: 120px;
  padding-bottom: 0px;
  background: #0F0028;
  color: white;
}

.contactlessTitle {
  font-family: 'Ranade', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  /* or 62px */

  letter-spacing: -0.04em;

  /* Main/White */

  color: #FFFFFF;
  margin-bottom: 24px;
}

.subtext {
  font-family: 'Ranade', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  /* or 29px */

  letter-spacing: -0.02em;

  /* Shades/Dark-15% */

  color: #DBD9DF;
  margin-bottom: 40px;
}

.rightImage {
  width: 518.55px;
  height: 533.99px;
}

@media (max-width: 1453px) {
  .rightImage {
    width: 300.55px;
    height: auto;
  }

  .contactlessImgDiv {
    justify-content: end;
  }
}

@media (max-width: 983px) {
  .rightImage {
    width: 300.55px;
    height: auto;
  }

  .contactlessImgDiv {
    align-items: center;
  }
  .siteContent {
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
  }

  .contactlessDiv {
    flex-direction: column !important;
  }
}

@media (max-width: 775px) {

  .contactlessSection {
    padding: 40px;
    padding-bottom: 0;
  }

  .contactlessTitle {
    font-size: 32px;
  }

  .subtext {
    font-size: 16px;
  }
}

@media (max-width: 592px) {
  .contactlessTitle {
    font-size: 26px;
  }

  .subtext {
    font-size: 12px;
  }
}
