.cardSection {
  padding: 2rem 0;
  background: #F3F2F4;
}

.leftImage {
  width: 100%;
  height: auto;
  margin-top: 1rem;
}

.card {
  padding: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  border-radius: 16px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;

  .cardtitle {
    font-family: 'Ranade', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    /* or 31px */

    letter-spacing: -0.04em;

    /* Main/Dark */

    color: #0F0028;
  }

  .cardSubtext {
    font-family: 'Ranade', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    letter-spacing: -0.02em;

    /* Main/Paragraph */

    color: #665C76;

  }

  img {
    width: 100%;
    height: auto;
  }

  h3 {
    margin: 1rem 0;
  }

  p {
    color: #777;
  }
}

.centeredColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
