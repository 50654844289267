.featuresSection {
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}

.titleName {
  font-family: 'Ranade', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.04em;
  color: #0F0028;
  margin-bottom: 12px;
}

.subtext {
  font-family: 'Ranade', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: #665C76;
  margin-bottom: 0px;
}

.cardImage {
  object-fit: cover;
  border-radius: 16px;
  //border-radius: 16px;
  max-height: 196px;
  //width: auto !important;
  //height: auto !important;
}

.imgDiv {
 height: 196px;
  display: flex;
  justify-content: start;
  align-content: start;
  align-items: start;
}

.centered {
  justify-content: center;
  align-content: center;
  align-items: center;
}

.card {
  background: #F3F2F4;
  border-radius: 16px;
  height: 410px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 410px;
}

.card img {
  width: 100%;
  height: auto;
}

.card h3 {
  font-family: 'Ranade', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  letter-spacing: -0.04em;

  /* Main/Dark */

  color: #0F0028;
  padding-left: 40px;
  padding-right: 40px;
}

.card p {
  color: #777;
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 520px) {
  .card {
    width: 300px;
    height: fit-content;

  }
}
