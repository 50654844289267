.customButton {
  background: linear-gradient(180deg, #EBFFBB 0%, #C2FF2E 100%);
  border-radius: 8px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0;
  cursor: pointer;
  padding: 10px 24px;
  transition-duration: 0.4s;
  color: #0F0028;

  font-family: 'Ranade', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.02em;
  width: fit-content;
}

.customButton:hover {
  filter: brightness(85%);
  color: #0F0028 !important;
}
