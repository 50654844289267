.whyChooseSection {
  background: #0F0028;
}

.textAccordionDiv {
  padding-left: 120px;
  padding-top: 120px;
}

.rightImage {
  height: auto;
  width: auto;
}

.freelancerTitle {
  font-family: 'Ranade', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  /* or 62px */

  letter-spacing: -0.04em;

  /* Main/White */

  color: #FFFFFF;
}

.accordion {
  margin-top: 1rem;

  .accordionItem {
    background-color: transparent;
    margin-bottom: 10px; // Add the gap between accordion items
    border: 1px solid #5835D6 !important;
    border-radius: 8px;
  }

  .accordionHeader {
    background-color: transparent;
    font-family: 'Ranade', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    /* identical to box height, or 29px */

    letter-spacing: -0.02em;

    /* Main/White */

    color: #FFFFFF;

    button {
      background-color: transparent;
      font-family: 'Ranade', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 160%;
      /* identical to box height, or 29px */

      letter-spacing: -0.02em;

      /* Main/White */

      color: #FFFFFF;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .accordionBody {
    background-color: transparent;
    font-family: 'Ranade', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    letter-spacing: -0.02em;

    /* Shades/Dark-15% */

    color: #DBD9DF;
  }
}


.accordionCard {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

@media (max-width:1491px) {
  .whyChooseDiv {
    flex-direction: column !important;
  }

  .textAccordionDiv {
    padding-right: 120px;
  }

  .rightImage {
    height: 497px !important;
    width: auto;
    padding-right: 120px;
  }
}

@media (max-width: 991px) {
  .freelancerTitle {
    font-size: 32px;
  }
  .rightImage {
    padding-right: 40px;

  }
  .textAccordionDiv {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
  }

}

@media (max-width: 614px) {
  .freelancerTitle {
    font-size: 20px;
    text-align: center;
  }

  .rightImage {
    padding-right: 20px;

  }
  .textAccordionDiv {
    padding-left: 20px;
    padding-right: 20px;
  }
}


