.footer {
  background: #0F0028;
  color: #fff;
  padding: 2rem 0;
  border-top: white 1px solid;
}

.footerDiv {
  padding-left: 40px;
  padding-right: 40px;
}

.logo {
  height: auto;
  margin-bottom: 1rem;
}

.boldSubtext {
  font-weight: bold;
}

.comingSoon {
  display: inline-block;
  background-color: #f00;
  color: #fff;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  margin-top: 0.5rem;
}

.socialLinks {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 1rem;

  li {
    img {
      width: 30px;
      height: auto;
    }
  }
}

@media (min-width: 768px) {

}
