.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #0F0028;
  padding: 0 2rem;
  z-index: 100;
  height: 4rem;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.getStartedButton {
  background-color: #4caf50;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  padding: 10px 24px;
  border-radius: 4px;
  transition-duration: 0.4s;
}

@media (max-width: 540px) {
  .buttonCustomStyle {
    font-size: 10px !important;
    width: 100px;
    padding: 5px !important;
  }

  .navbar {
    padding: 0 1rem !important;
  }


  .logoContainer {
    width: 140px !important;
    height: auto !important;
  }
}
.getStartedButton:hover {
  background-color: #45a049;
  color: white;
}
